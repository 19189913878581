export function setUpThemeChangers() {

  function applyActiveClass() {
    //console.log('applyActiveClass');
    const lis = document.querySelectorAll('li[data-li-subnav-class]');
    // Determine the effective theme
    const theme = localStorage.theme || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    lis.forEach(li => {
      // Check for a match with the current effective theme or default to system if no theme is set in localStorage
      const isActive = localStorage.theme ? li.querySelector(`[data-color-scheme-${theme}]`) : li.querySelector('[data-color-scheme-system]');
      if (isActive) {
        li.className = li.dataset.liSubnavActiveClass;
      } else {
        li.className = li.dataset.liSubnavClass;
      }
    });
  }

  applyActiveClass();

  function pauseHoverTransitions() {
    //console.log('pauseHoverTransitions');
    // Disable transitions on navigation items while the theme switches
    const navItems = document.querySelectorAll('#navigation-items li');
    navItems.forEach(item => {
      item.style.transition = 'none'; // Disable transitions
    });
  
    // Re-enable transitions after a short delay
    setTimeout(() => {
      navItems.forEach(item => {
        item.style.transition = ''; // Re-enable transitions
      });
    }, 50); // Adjust the delay as needed
  }

  // This deals with checking and setting dark mode when a user chooses to from the menu
  // To check and set the dark mode when the site first loads and on subsequent page navigations via View Transitions happens in a script in Layout.astro head.
  function checkDarkMode(document, fromOS) {
    pauseHoverTransitions();
    document.documentElement.classList.toggle('dark', localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches));
    if(fromOS !== true) {
      applyActiveClass();
    }
  }
  
  function setTheme(theme) {
    localStorage.setItem('theme', theme);
    checkDarkMode(document);
  }
  
  function clearTheme() {
    localStorage.removeItem('theme');
    checkDarkMode(document);
  }

  function handleOSColorSchemeChange(event) {
    if (event.matches) {
      //console.log('OS set to dark mode');
      checkDarkMode(document, true);
    }else{
      //console.log('OS set to light mode');
      checkDarkMode(document, true);
    }
  }

  const lightButton = document.querySelector('[data-color-scheme-light]');
  const darkButton = document.querySelector('[data-color-scheme-dark]');
  const systemButton = document.querySelector('[data-color-scheme-system]');

  if (lightButton) lightButton.addEventListener('click', () => setTheme('light'));
  if (darkButton) darkButton.addEventListener('click', () => setTheme('dark'));
  if (systemButton) systemButton.addEventListener('click', clearTheme);

  // ensure dark mode is set the moment that it is activated on the user's OS
  const OSColorSchemeCheck = window.matchMedia('(prefers-color-scheme: dark)');
  OSColorSchemeCheck.addEventListener('change', handleOSColorSchemeChange);
}