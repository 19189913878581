import { navigate } from 'astro:transitions/client';
import { isViewTransitionSupported } from './checkFeatureSupport.js';

export function subnavToggles() {
  const toggleElements = document.querySelectorAll('[data-sub-nav-toggle]');
  
  toggleElements.forEach((toggleElement) => {
    const subNav = toggleElement.querySelector('[data-sub-nav]');

    // Remove scaling on click for this primary nav option
    toggleElement.classList.remove('active:scale-[0.95]');

    if (!subNav) return; // Exit early if no subnav exists

    let hideTimeout; // Variable to store timeout ID

    const hideSubNav = () => {
      subNav.classList.add('hidden');
    };

    const showSubNav = () => {
      subNav.classList.remove('hidden');
      clearTimeout(hideTimeout); // Clear any existing hide timeout when hovering
    };

    // Prevent default link behavior for li a inside the subnav
    const links = subNav.querySelectorAll('li a');

    links.forEach((link) => {
      link.addEventListener('click', (event) => {
        console.log(event)
        if(isViewTransitionSupported) {
          event.preventDefault(); // Prevent the default link behavior (navigation)
          let href = link.href;
          navigate(href);
        }
      });
    });

    // Show subnav on mouse enter for both the toggle element and subnav
    const handleMouseEnter = () => {
      showSubNav();
    };

    toggleElement.addEventListener('mouseenter', handleMouseEnter);
    subNav.addEventListener('mouseenter', handleMouseEnter);

    // Hide subnav with a delay when mouse leaves both the toggle and subnav
    const handleMouseLeave = () => {
      if (!subNav.matches(':hover')) {
        hideTimeout = setTimeout(hideSubNav, 50); // Delay of 150ms before hiding
      }
    };

    toggleElement.addEventListener('mouseleave', handleMouseLeave);
    subNav.addEventListener('mouseleave', handleMouseLeave);

    // Hide subnav if mouse leaves both the subnav and the toggle element (document-wide)
    document.addEventListener('mouseover', (event) => {
      if (!toggleElement.contains(event.target) && !subNav.contains(event.target)) {
        clearTimeout(hideTimeout); // Clear any existing hide timeout
        hideTimeout = setTimeout(hideSubNav, 50); // Delay of 50ms before hiding
      }
    });

    // Hide subnav on scroll
    window.addEventListener('scroll', () => {
      hideSubNav();
      clearTimeout(hideTimeout); // Clear any pending hide timeout
    });
  });
}
